import React from 'react'
import Skeleton from 'react-loading-skeleton'

function CardSkeleton() {
    return (
        <>
            <div className='movie-img All-Mappinghyper'>
                <div>
                    <div className='movie-imgs BackgroundImage'>
                        <Skeleton />
                    </div>
                    <h6>Loading...</h6>
                </div>
            </div>
            <div className='movie-img All-Mappinghyper'>
                <div>
                    <div className='movie-imgs BackgroundImage'>
                        <Skeleton />
                    </div>
                    <h6>Loading...</h6>
                </div>
            </div>
            <div className='movie-img All-Mappinghyper'>
                <div>
                    <div className='movie-imgs BackgroundImage'>
                        <Skeleton />
                    </div>
                    <h6>Loading...</h6>
                </div>
            </div>
            <div className='movie-img All-Mappinghyper'>
                <div>
                    <div className='movie-imgs BackgroundImage'>
                        <Skeleton />
                    </div>
                    <h6>Loading...</h6>
                </div>
            </div>
            <div className='movie-img All-Mappinghyper'>
                <div>
                    <div className='movie-imgs BackgroundImage'>
                        <Skeleton />
                    </div>
                    <h6>Loading...</h6>
                </div>
            </div>
            <div className='movie-img All-Mappinghyper'>
                <div>
                    <div className='movie-imgs BackgroundImage'>
                        <Skeleton />
                    </div>
                    <h6>Loading...</h6>
                </div>
            </div>
            <div className='movie-img All-Mappinghyper'>
                <div>
                    <div className='movie-imgs BackgroundImage'>
                        <Skeleton />
                    </div>
                    <h6>Loading...</h6>
                </div>
            </div>
            <div className='movie-img All-Mappinghyper'>
                <div>
                    <div className='movie-imgs BackgroundImage'>
                        <Skeleton />
                    </div>
                    <h6>Loading...</h6>
                </div>
            </div>
            <div className='movie-img All-Mappinghyper'>
                <div>
                    <div className='movie-imgs BackgroundImage'>
                        <Skeleton />
                    </div>
                    <h6>Loading...</h6>
                </div>
            </div>
            <div className='movie-img All-Mappinghyper'>
                <div>
                    <div className='movie-imgs BackgroundImage'>
                        <Skeleton />
                    </div>
                    <h6>Loading...</h6>
                </div>
            </div>
        </>
    )
}

export default CardSkeleton
import React from 'react'
import Moviemapping from '../Components/Moviemapping'

function Home() {
  return (
    <>
      <Moviemapping />
    </>
  )
}

export default Home
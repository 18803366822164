import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
     <footer class="footer-section">
        <div class="copyright-area">
            <div class="container">
                <div class="row">
                    <div class="footer-copy-width-1 col-xl-6 col-lg-6 text-lg-left">
                        <div class="copyright-text">
                            <p>Copyright &copy; 2024, Reserved Created By <Link to="/contact">JScoder</Link> & <Link to="/contact">UTcoder</Link></p>
                        </div>
                    </div>
                    <div class="footer-copy-width-2 col-xl-6 col-lg-6 d-lg-block text-right">
                        <div class="footer-menu">
                            <ul style={{margin: 0, display: "inline", float: "right"}}>
                                <li><a href="/">Home</a></li>
                                <li><a href="/tearms-and-condition">Terms</a></li>
                                <li><a href="/privacy-policy">Privacy & Policy</a></li>
                                <li><a href="/contact">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer
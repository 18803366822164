import React from 'react'
import NameImg from '../Assets/Logo-Name.png'

const Stylesheets = {
    LogoDiv: {
        width: "150px"
    },

    NameImg: {
        width: "100%"
    }
}

function Logo() {
  return (
    <>
      <div style={Stylesheets.LogoDiv} className="Nav-Logo">
        <img style={Stylesheets.NameImg} src={NameImg} alt="Name Png" />
      </div>
    </>
  )
}

export default Logo
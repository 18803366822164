import React, { useEffect } from 'react'
import { preLoaderAnim } from '../Animation/Index';

const Preloader = () => {

    useEffect(() => {
        preLoaderAnim()
    }, []);

    const Stylesheets = {
        HgColors: {
            color: "#b082ff",
        }
    }

    return (
        <div className='preloader' style={{
            cursor: "none",
        }}>
            <div className='texts-container'>
                <span><span style={Stylesheets.HgColors}>W</span>elcome</span>
                <span><span style={Stylesheets.HgColors}>T</span>o</span>
                <span><span style={Stylesheets.HgColors}>J</span>ustify</span>
                <span><span style={Stylesheets.HgColors}>M</span>ovies</span>
                <span style={Stylesheets.HgColors}><i class="fa-regular fa-face-smile"></i></span>
            </div>
        </div>
    )
}

export default Preloader
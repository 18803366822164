import React from 'react'

function Contact() {
  return (
    <>
    <title>Justify Movies - Contact Page Contact For Develope Your Own Gaming Website At Low Cost</title>

      <div className="wrapper">
        <p>Welcome to the Contact Us page for <b><h5 style={{color: "#8C52FE", display: "inline"}}>Justify Movies</h5></b>! We're delighted to assist you with any inquiries or feedback you may have. Please find below the various ways you can reach out to us:</p>

        <h2>1. Contact Form:</h2>
        <p>For general inquiries, feedback, or support requests, please fill out our contact form on our website. We strive to respond to all inquiries within 24-48 hours.</p>

        <h2>2. Email Support:</h2>
        <p>You can directly email us at <b><h5 style={{color: "#8C52FE", display: "inline"}}>"mr.jeelsardhara@gmail.com"</h5></b> for any specific queries related to account issues, technical support, or suggestions for improving our platform.</p>

        <h2>3. FAQs:</h2>
        <p>Before reaching out, you might find the answer to your question in our Frequently Asked Questions section. Check it out where we cover common topics such as account management, gameplay, and troubleshooting.</p>

        <h2>4. Business Partnerships:</h2>
        <p>If you're interested in collaborating with us or exploring business opportunities, please contact our partnerships team at <b><h5 style={{color: "#8C52FE", display: "inline"}}>femiltalaviya@gmail.com</h5></b>.</p>
        <p>If you're interested in collaborating with us or exploring business opportunities, please contact our partnerships team at <b><h5 style={{color: "#8C52FE", display: "inline"}}>utsavpatel147208@gmail.com</h5></b>.</p>

        <h2>5. Address:</h2>
        <b><h5 style={{color: "#8C52FE", display: "inline"}}>Patels Pvt. Ltd.</h5></b>
        <br />
        <b><h5 style={{color: "#8C52FE", display: "inline"}}>Surat, Gujarat, India</h5></b>

        <p>Please note: Our office is not open to the public, and visits are by appointment only.</p>

        <p>Customer Support Hours:</p>
        <p>Our customer support team is available 12/7 to assist you promptly. If you contact us outside of these hours, we will get back to you as soon as possible on the next business day.</p>

        <p>Thank you for choosing <b><h5 style={{color: "#8C52FE", display: "inline"}}>Justify Movies</h5></b>! We value your feedback and are committed to providing you with the best gaming experience possible.</p>
      </div>
    </>
  )
}

export default Contact
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import Preloader from "./Components/Preloader"
import Home from "./Views/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Contact from "./Views/Contact";
import Terms from "./Views/Terms";
import Privacypolicy from "./Views/Privacypolicy";
import Blog from "./Views/Blog";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  return (
    <>
    <Preloader />
      <Navbar />
      <Routes>
        <Route key="page-route" exact path="/" element={<Home />} />
        <Route key="page-route" exact path="/contact" element={<Contact />} />
        <Route key="page-route" exact path="/blog" element={<Blog />} />
        <Route key="page-route" exact path="/tearms-and-condition" element={<Terms />} />
        <Route key="page-route" exact path="/privacy-policy" element={<Privacypolicy />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Search() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetch('https://justifymovies.onrender.com/mappingmovie')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setFilteredData(data);
      })
      .catch(err => console.error(err));
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFilter = (value) => {
    const lowerCaseValue = value.toLowerCase();
    const filtered = data.filter(item => item.movieName.toLowerCase().includes(lowerCaseValue));
    setFilteredData(filtered);
  }

  return (
    <>
      <Button className="nav-link nav-btn" onClick={handleShow}>
        <i className="fa-solid fa-magnifying-glass"></i> Search
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Search Movie Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="text" onChange={e => handleFilter(e.target.value)} className='Search-Input' placeholder='Search..' />

          <div className='Search-Result'>
            {
              filteredData.map((d, i) => (
                <div key={i}>
                  <a className='Search-Link' href={d.movieUrl}>
                    {d.movieName}
                  </a>
                </div>
              ))
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Search;

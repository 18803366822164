import React, { useState, useEffect } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import CardSkeleton from './CardSkeleton';

function MovieMapper() {
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {

      const dataUrl = "https://justifymovies.onrender.com/mappingmovie";

      fetch(dataUrl)
        .then(response => response.json())
        .then(data => {
          setMovies(data)
          setIsLoading(false)
        });
    }, 0)
  }, []);

  return (
    <>
      <SkeletonTheme baseColor="#ffffff" highlightColor="#ffffff">

        {
          isLoading
            ?
            <div className='All-Mapping'>
              <CardSkeleton/>
            </div>

            :

            <div className='All-Mapping'>
              {movies.map((movie, index) => (
                <a className='movie-img All-Mappinghyper' href={movie.movieUrl}>
                  <div key={index}>
                    <img className='movie-imgs' src={movie.imageUrl} alt={`Justify Movies Image ` + movie.movieName} />
                    <h6>{movie.movieName}</h6>
                  </div>
                </a>
              ))}
            </div>
        }
      </SkeletonTheme>
    </>
  );
}

export default MovieMapper;